import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    isError500ModalOpen: false,
    selectedProject: [],
    count: 0,
  },
  reducers: {
    show500ErrorModal: (state) => {
      state.isError500ModalOpen = true;
    },
    hide500ErrorModal: (state) => {
      state.isError500ModalOpen = false;
    },
    setSelectedProject: (state, action) => {
      state.selectedProject = action.payload;
    },
    setProjectCount: (state, action) => {
      state.count = action.payload;
    },
  },
});

export const {
  show500ErrorModal,
  hide500ErrorModal,
  setSelectedProject,
  setProjectCount,
} = appSlice.actions;
export default appSlice.reducer;
