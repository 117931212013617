import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import * as routeConfig from "../../routes/RouteConfig";
import { getInitials } from "../../utilities/utilities";
import { logout } from "../../redux/slices/authSlice";

const drawerWidth = 240;

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const TopBar = ({ isMobileNavOpen, setMobileNavOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [anchorElement, setAnchorElement] = useState(null);

  const onHandleMenu = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const onHandleClose = () => {
    setAnchorElement(null);
  };

  const onHandleProfile = () => {
    onHandleClose();
    navigate(routeConfig.USER_SETTINGS);
  };

  const onHandleProjects = () => {
    onHandleClose();
    navigate(routeConfig.PROJECTS);
  };

  const onHandleCompanySettings = () => {
    onHandleClose();
    navigate(routeConfig.COMPANY);
  };

  const onHandleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="toggle drawer"
            edge="start"
            onClick={() => setMobileNavOpen(!isMobileNavOpen)}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings" placement="bottom-start">
              <IconButton
                aria-label={getInitials(`${user.first_name} ${user.last_name}`)}
                aria-haspopup="true"
                onClick={onHandleMenu}
                sx={{ p: 0 }}
              >
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  variant="dot"
                >
                  <Avatar
                    src={user.avatar}
                    alt={getInitials(`${user.first_name} ${user.last_name}`)}
                  >
                    {getInitials(`${user.first_name} ${user.last_name}`)}
                  </Avatar>
                </StyledBadge>
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElement}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElement)}
              onClose={onHandleClose}
              sx={{ mt: "45px" }}
            >
              <MenuItem onClick={onHandleProfile}>Profile</MenuItem>
              <MenuItem onClick={onHandleProjects}>Projects</MenuItem>
              <MenuItem onClick={onHandleCompanySettings}>
                Company Settings
              </MenuItem>
              <MenuItem onClick={onHandleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

TopBar.propTypes = {
  isMobileNavOpen: PropTypes.bool,
  setMobileNavOpen: PropTypes.func.isRequired,
};

export default TopBar;
