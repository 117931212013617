import { createSlice } from "@reduxjs/toolkit";

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: {
    snackbar: false,
    type: "",
    message: "",
    autoHideDuration: 3000,
  },
  reducers: {
    updateSnackbar: (state, action) => {
      state.snackbar = action.payload.snackbar;
      state.type = action.payload.type;
      state.message = action.payload.message;
      state.autoHideDuration = action.payload.autoHideDuration;
    },
  },
});

export const { updateSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
