class _PersistentStorageService {
  constructor() {
    this.persistentDataKeys = ["project"];
  }

  // storage methods related to project
  persistSelectedProject = (project) => {
    localStorage.setItem("project", JSON.stringify(project));
  };

  getSelectedProject = () => {
    const project = localStorage.getItem("project");
    if (project) {
      return JSON.parse(project);
    }
    return project;
  };

  cleanUpStorage = () => {
    this.persistentDataKeys.forEach((dataKey) => {
      localStorage.removeItem(dataKey);
    });
  };
}

const PersistentStorageService = new _PersistentStorageService();

export default PersistentStorageService;
