import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import APIService from "../../services/apiService";
import AuthTokenService from "../../services/authTokenService";

export const loginUser = createAsyncThunk(
  "auth/login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await APIService.loginUser(credentials);
      AuthTokenService.setAuthToken(response.access);
      AuthTokenService.setRefreshToken(response.refresh);
      return response;
    } catch (error) {
      console.error("Login error:", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const refreshToken = createAsyncThunk(
  "auth/refresh",
  async (_, { rejectWithValue }) => {
    try {
      const refreshToken = AuthTokenService.getRefreshToken();
      const response = await APIService.refreshToken(refreshToken);
      AuthTokenService.setAuthToken(response.access);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    isAuthenticated: false,
    loading: false,
    error: null,
    company: null,
    role: null,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      AuthTokenService.removeAuthToken();
      AuthTokenService.removeRefreshToken();
    },
    updateCompany: (state, action) => {
      state.company = action.payload.company;
    },
    updateRole: (state, action) => {
      state.role = action.payload.user_type;
    },
    updateUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.user = action.payload.user;
        state.company = action.payload.user.company;
        state.role = action.payload.user.user_type;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.error = action.payload || "Login failed";
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.isAuthenticated = true;
      })
      .addCase(refreshToken.rejected, (state) => {
        state.isAuthenticated = false;
        state.user = null;
      });
  },
});

export const { logout, updateCompany, updateRole, updateUser } =
  authSlice.actions;
export default authSlice.reducer;
