import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useOutlet } from "react-router-dom";
import { styled } from "@mui/system";

import * as routeConfig from "../../routes/RouteConfig";

const MainContent = styled("main")({
  height: "100%",
});

const Auth = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const outlet = useOutlet();

  if (isAuthenticated) {
    return <Navigate to={routeConfig.DASHBOARD} replace />;
  }

  return (
    <>
      <MainContent>{outlet}</MainContent>
    </>
  );
};

export default Auth;
