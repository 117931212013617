class AuthTokenService {
  static getAuthToken() {
    return localStorage.getItem("authToken");
  }

  static setAuthToken(token) {
    localStorage.setItem("authToken", token);
  }

  static removeAuthToken() {
    localStorage.removeItem("authToken");
  }

  static getAuthHeader() {
    const token = this.getAuthToken();
    return token ? { Authorization: `Bearer ${token}` } : {};
  }

  static getRefreshToken() {
    return localStorage.getItem("refreshToken");
  }

  static setRefreshToken(token) {
    localStorage.setItem("refreshToken", token);
  }

  static removeRefreshToken() {
    localStorage.removeItem("refreshToken");
  }

  static handleLogoutSuccess() {
    this.removeAuthToken();
  }
}

export default AuthTokenService;
