import React from "react";
import {
  Code,
  Dashboard,
  Description,
  Engineering,
  People,
  Plumbing,
  Settings,
  SettingsSuggest,
  VerifiedUser,
} from "@mui/icons-material";

import * as routeConfig from "../../routes/RouteConfig";

const navBarConfig = {
  company_admin: [
    {
      icon: <Dashboard />,
      text: "Overview",
      href: routeConfig.DASHBOARD,
    },
    {
      icon: <Description />,
      text: "Specifications",
      href: routeConfig.SPECIFICATIONS,
    },
    {
      icon: <People />,
      text: "Users",
      href: routeConfig.USERS,
    },
    {
      icon: <VerifiedUser />,
      text: "Groups",
      href: routeConfig.USERS,
    },
    {
      icon: <Code />,
      text: "Codes",
      href: routeConfig.CODES,
    },
    {
      icon: <Engineering />,
      text: "Engineering Items",
      href: routeConfig.ENGINEERINGITEMS,
    },
    {
      icon: <Settings />,
      text: "Parts",
      href: routeConfig.PARTS,
    },
    {
      icon: <SettingsSuggest />,
      text: "Part Configurations",
      href: routeConfig.PARTCONFIGURATIONS,
    },
    {
      icon: <Plumbing />,
      text: "Operators",
      href: routeConfig.OPERATORS,
    },
  ],
  material_engineer: [
    {
      icon: <Dashboard />,
      text: "Overview",
      href: routeConfig.DASHBOARD,
    },
    {
      icon: <Description />,
      text: "Specifications",
      href: routeConfig.SPECIFICATIONS,
    },
    {
      icon: <Code />,
      text: "All Codes",
      href: routeConfig.CODES,
    },
    {
      icon: <Engineering />,
      text: "Engineering Items",
      href: routeConfig.ENGINEERINGITEMS,
    },
    {
      icon: <Settings />,
      text: "Parts",
      href: routeConfig.PARTS,
    },
    {
      icon: <SettingsSuggest />,
      text: "Part Configurations",
      href: routeConfig.PARTCONFIGURATIONS,
    },
    {
      icon: <Plumbing />,
      text: "Operators",
      href: routeConfig.OPERATORS,
    },
  ],
  design_engineer: [
    {
      icon: <Dashboard />,
      text: "Overview",
      href: routeConfig.DASHBOARD,
    },
    {
      icon: <Description />,
      text: "Specifications",
      href: routeConfig.SPECIFICATIONS,
    },
  ],
};

export default navBarConfig;
