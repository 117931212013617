import React from "react";
import { Outlet } from "react-router-dom";

const Error = () => {
  return (
    <main
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {<Outlet />}
    </main>
  );
};

export default Error;
