import HttpService from "./httpService";
import API_URLS from "../constants/api";

class _APIService {
  /**
   * Login
   */
  loginUser = (credentials) => {
    return HttpService.post(API_URLS.LOGIN, credentials).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Refresh Token
   */
  refreshToken = (refreshToken) => {
    return HttpService.post(API_URLS.REFRESH_TOKEN, {
      refresh: refreshToken,
    }).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Reset Password
   */
  resetPassword = (data) => {
    return HttpService.post(API_URLS.RESET_PASSWORD, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Change Password
   */
  changePassword = (data) => {
    return HttpService.post(API_URLS.CHANGE_PASSWORD, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Send Recovery Link
   */
  sendRecoveryLink(data) {
    return HttpService.post(API_URLS.FORGOT_PASSWORD, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  }

  /**
   * Create User
   */
  postUser = (companyId, data) => {
    const URL = API_URLS.USERS.replace("{companyId}", companyId);
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List Users
   */
  getUsers = (companyId, params) => {
    const URL = API_URLS.USERS.replace("{companyId}", companyId);
    // console.log(URL);
    return HttpService.get(URL, params).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * User Details
   */
  getUser = (companyId, userId) => {
    const URL = `${API_URLS.USERS.replace("{companyId}", companyId)}${userId}/`;
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Update User Details
   */
  putUser = (companyId, userId, data) => {
    const URL = `${API_URLS.USERS.replace("{companyId}", companyId)}${userId}/`;
    return HttpService.put(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Update User Details
   */
  patchUser = (companyId, userId, data) => {
    const URL = `${API_URLS.USERS.replace("{companyId}", companyId)}${userId}/`;
    return HttpService.patch(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Update Company Details
   */
  patchCompany = (companyId, data) => {
    const URL = API_URLS.COMPANY.replace("{companyId}", companyId);
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return HttpService.patch(URL, formData, {
      "Content-Type": "multipart/form-data",
    }).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List Projects
   */
  getProjects = (companyId, params) => {
    const URL = API_URLS.PROJECTS.replace("{companyId}", companyId);
    return HttpService.get(URL, params).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Get Project
   */
  getProject = (companyId, projectId) => {
    const URL = `${API_URLS.PROJECTS.replace("{companyId}", companyId)}${projectId}/`;
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Create Project
   */
  postProject = (companyId, data) => {
    const URL = API_URLS.PROJECTS.replace("{companyId}", companyId);
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Patch Project
   */
  patchProject = (companyId, projectId, data) => {
    const URL = `${API_URLS.PROJECTS.replace(
      "{companyId}",
      companyId
    )}${projectId}/`;
    return HttpService.patch(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Put Project
   */
  putProject = (companyId, projectId, data) => {
    const URL = `${API_URLS.PROJECTS.replace(
      "{companyId}",
      companyId
    )}${projectId}/`;
    return HttpService.put(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List Specifications
   */
  getSpecifications = (companyId, projectId, params) => {
    const URL = API_URLS.SPECIFICATIONS.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.get(URL, params).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Get Specification
   */
  getSpecification = (companyId, projectId, specificationId) => {
    const URL = `${API_URLS.SPECIFICATIONS.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${specificationId}/`;
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Create Specification
   */
  postSpecification = (companyId, projectId, data) => {
    const URL = API_URLS.SPECIFICATIONS.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Create Specification Version
   */
  postSpecificationVersion = (companyId, projectId, specificationId, data) => {
    const URL = `${API_URLS.SPECIFICATION_VERSIONS.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)}`;
    const formData = new FormData();
    formData.append("version", data.version);
    formData.append("file", data.file);
    formData.append("company", companyId);
    return HttpService.post(URL, formData, {
      "Content-Type": "multipart/form-data",
    }).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Update Specification Version
   */
  patchSpecificationVersion = (
    companyId,
    projectId,
    specificationId,
    versionId,
    data
  ) => {
    const URL = `${API_URLS.SPECIFICATION_VERSIONS.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId)}${versionId}/`;
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return HttpService.patch(URL, formData, {
      "Content-Type": "multipart/form-data",
    }).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Create Specification Version Image
   */
  postSpecificationVersionImage = (
    companyId,
    projectId,
    specificationId,
    versionId,
    data
  ) => {
    const URL = `${API_URLS.SPECIFICATION_VERSION_IMAGES.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId)}`;
    const formData = new FormData();
    formData.append("image", data.image);
    return HttpService.post(URL, formData, {
      "Content-Type": "multipart/form-data",
    }).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Update Specification Version Image
   */
  patchSpecificationVersionImage = (
    companyId,
    projectId,
    specificationId,
    versionId,
    imageId,
    data
  ) => {
    const URL = `${API_URLS.SPECIFICATION_VERSION_IMAGES.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId)}${imageId}/`;
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return HttpService.patch(URL, formData, {
      "Content-Type": "multipart/form-data",
    }).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List specification extractions
   */
  getSpecificationExtractions = (
    companyId,
    projectId,
    specificationId,
    versionId,
    hasBranchTable
  ) => {
    const extractionURL = hasBranchTable
      ? API_URLS.SPECIFICATION_VERSION_BRANCH_TABLE_EXTRACTIONS
      : API_URLS.SPECIFICATION_VERSION_EXTRACTIONS;
    const URL = extractionURL
      .replace("{companyId}", companyId)
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId);
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Extraction Actions
   */
  postExtractionAction = (
    companyId,
    projectId,
    specificationId,
    versionId,
    extractionId,
    action,
    data = {}
  ) => {
    const URL = `${API_URLS.SPECIFICATION_VERSION_EXTRACTIONS.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId)}${extractionId}/`;
    return HttpService.post(URL, {
      action: action,
      ...data,
    }).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Extraction Actions
   */
  patchExtraction = (
    companyId,
    projectId,
    specificationId,
    versionId,
    extractionId,
    data
  ) => {
    const URL = `${API_URLS.SPECIFICATION_VERSION_EXTRACTIONS.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId)}${extractionId}/`;
    return HttpService.patch(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List specification transformations
   */
  getSpecificationTransformations = (
    companyId,
    projectId,
    specificationId,
    versionId,
    type
  ) => {
    const transformationURL =
      type === "specification"
        ? API_URLS.SPECIFICATION_VERSION_TRANFORMATIONS
        : API_URLS.SPECIFICATION_VERSION_CATALOG_TRANFORMATIONS;
    const URL = transformationURL
      .replace("{companyId}", companyId)
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId);
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Transformation Actions
   */
  postTrasformationAction = (
    companyId,
    projectId,
    specificationId,
    versionId,
    transformationId,
    action,
    data = {}
  ) => {
    const URL = `${API_URLS.SPECIFICATION_VERSION_TRANFORMATIONS.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId)}${transformationId}/`;
    return HttpService.post(URL, {
      action: action,
      ...data,
    }).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Update Specification Version ETL Process
   */
  patchSpecificationVersionETLProcess = (
    companyId,
    projectId,
    specificationId,
    versionId,
    processId,
    data
  ) => {
    const URL = `${API_URLS.SPECIFICATION_VERSION_ETL_PROCESSES.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId)}${processId}/`;
    return HttpService.patch(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List Part
   */
  getParts = (companyId, projectId, params) => {
    const URL = API_URLS.PARTS.replace("{companyId}", companyId).replace(
      "{projectId}",
      projectId
    );
    return HttpService.get(URL, params).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Get Specification
   */
  getSpecificationExtractionStep = (
    companyId,
    projectId,
    specificationId,
    versionId,
    stepId
  ) => {
    const URL = `${API_URLS.SPECIFICATION_VERSION_ETL_STEPS.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId)}${stepId}/`;
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  getSpecificationExcelFile = (
    type,
    companyId,
    projectId,
    specificationId,
    versionId,
    params,
    additionalConfig = {}
  ) => {
    const excelFileURL =
      type === "specification"
        ? API_URLS.SPECIFICATION_VERSION_DOWNLOAD_EXCEL
        : API_URLS.SPECIFICATION_VERSION_DOWNLOAD_EXCEL;
    const URL = `${excelFileURL
      .replace("{companyId}", companyId)
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId)}`;
    return HttpService.getExcelFile(URL, params, additionalConfig).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };
}

const APIService = new _APIService();
export default APIService;
