import React, { Suspense } from "react";
import { LinearProgress } from "@mui/material";
import ErrorBoundary from "./error-boundary.hoc";

const lazyLoad = (Component) => {
    return class Lazy extends React.PureComponent {
        render() {
            const self = this;
            return (
                <ErrorBoundary>
                    <Suspense fallback={<LinearProgress />}>
                        <Component {...self.props} />
                    </Suspense>
                </ErrorBoundary>
            );
        }
    };
};

export default lazyLoad;
