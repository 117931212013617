import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Drawer,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  setProjectCount,
  setSelectedProject,
} from "../../redux/slices/appSlice";
import APIService from "../../services/apiService";
import PersistentStorageService from "../../services/persistentStorageService";
import NavBarConfig from "./NavBarConfig";

const drawerWidth = 240;

const menuProps = {
  PaperProps: {
    style: {
      maxWidth: "100%",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

const NavBar = (props) => {
  const { isMobileNavOpen, setMobileNavOpen } = props;
  const { user, company } = useSelector((state) => state.auth);
  const { count } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedPath, setSelectedPath] = useState(location.pathname);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [projects, setProjects] = useState([]);
  const [userSelectedProject, setUserSelectedProject] = useState(
    projects.length > 0 ? projects[0].id : ""
  );

  const getProjects = () => {
    setLoading(true);
    APIService.getProjects(company.id).then(
      (response) => {
        setLoading(false);
        setProjects(response.results);
        dispatch(setProjectCount(response.results.length));

        const project = PersistentStorageService.getSelectedProject();

        // restore previous selected project if present
        if (project) {
          setUserSelectedProject(project.id);
          dispatch(setSelectedProject(project));
        } else if (response.results.length > 0) {
          setUserSelectedProject(response.results[0].id);
          dispatch(setSelectedProject(response.results[0]));
        } else {
          setUserSelectedProject([]);
          dispatch(setSelectedProject([]));
        }
      },
      (error) => {
        setLoading(false);
        setError(error.message);
      }
    );
  };

  // ------------ UI HANDLERS ----------------
  const handleProjectChange = (event) => {
    const {
      target: { value: projectId },
    } = event;
    setUserSelectedProject(projectId);
    const project = projects.find((project) => project.id === projectId);
    dispatch(setSelectedProject(project));
    PersistentStorageService.persistSelectedProject(project);
  };

  const handleNavItem = (href) => {
    setSelectedPath(href);
    navigate(href);
  };

  // ---------------- UTILITIES -------------
  const isTabActive = (href) => {
    if (selectedPath === href || selectedPath.includes(href)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    getProjects();
  }, [count]);

  // --------------- UI --------------------
  const navbarContent = (
    <div>
      <Toolbar sx={{ px: 0 }}>
        <Box width={"100%"}>
          <Typography align="center" color="#5048E5" variant="h6">
            SPEC GENIE
          </Typography>
        </Box>
      </Toolbar>
      {projects.length > 0 && (
        <>
          <Box sx={{ px: 1 }}>
            <FormControl fullWidth>
              <InputLabel
                id="project-select-helper-label"
                disableAnimation={true}
                shrink={true}
              >
                Project
              </InputLabel>
              <Select
                labelId="project-select-helper-label"
                id="project-select-helper-label"
                value={userSelectedProject}
                label="Project"
                onChange={handleProjectChange}
                MenuProps={menuProps}
              >
                {projects.map((project) => (
                  <MenuItem value={project.id} key={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <List>
            {NavBarConfig[user.user_type] &&
              NavBarConfig[user.user_type].map(
                (item) =>
                  !item.hidden && (
                    <ListItem
                      button
                      key={item.text}
                      onClick={() => {
                        handleNavItem(item.href);
                      }}
                      selected={isTabActive(item.href)}
                    >
                      {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                      <ListItemText primary={item.text} />
                    </ListItem>
                  )
              )}
          </List>
        </>
      )}
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="navigation items"
    >
      <Drawer
        variant="temporary"
        anchor="left"
        open={isMobileNavOpen}
        onClose={() => setMobileNavOpen(!isMobileNavOpen)}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
          zIndex: (theme) => theme.zIndex.appBar + 100,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {navbarContent}
      </Drawer>
      <Drawer
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        variant="permanent"
        open
        anchor="left"
      >
        {navbarContent}
      </Drawer>
    </Box>
  );
};

NavBar.propTypes = {
  setMobileNavOpen: PropTypes.func.isRequired,
  isMobileNavOpen: PropTypes.bool,
};

export default NavBar;
