import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import lazyLoad from "../utilities/lazy-load.hoc";

import AuthLayout from "../layouts/auth/Auth";
import DashboardLayout from "../layouts/dashboard/Dashboard";
import ErrorLayout from "../layouts/error/Error";
import * as routeConfig from "./RouteConfig";

const LoginComponent = React.lazy(
  () => import(/* webpackChunkName: "login-page" */ "../pages/auth/Login")
);

const ForgotPasswordComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "forgot-password-page" */ "../pages/auth/ForgotPassword"
    )
);

const ResetPasswordComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "reset-password-page" */ "../pages/auth/ResetPassword"
    )
);

const DashboardComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-page" */ "../pages/dashboard/Dashboard"
    )
);

const SettingsComponent = React.lazy(
  () =>
    import(/* webpackChunkName: "settings-page" */ "../pages/settings/Settings")
);

const CodesComponent = React.lazy(
  () => import(/* webpackChunkName: "codes-page" */ "../pages/codes/Codes")
);

const CompanyComponent = React.lazy(
  () =>
    import(/* webpackChunkName: "company-page" */ "../pages/company/company")
);

const EngineeringItemsComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "engineering-items-page" */ "../pages/engineering-items/EngineeringItems"
    )
);

const Error401Component = React.lazy(
  () =>
    import(
      /* webpackChunkName: "error-401-page" */ "../pages/error401/Error401"
    )
);

const Error404Component = React.lazy(
  () =>
    import(
      /* webpackChunkName: "error-404-page" */ "../pages/error404/Error404"
    )
);

const Error500Component = React.lazy(
  () =>
    import(
      /* webpackChunkName: "error-500-page" */ "../pages/error500/Error500"
    )
);

const OperatorsComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "operators-page" */ "../pages/operators/Operators"
    )
);

const PartConfigurationsComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "part-configurations-page" */ "../pages/part-configurations/PartConfigurations"
    )
);

const PartsComponent = React.lazy(
  () => import(/* webpackChunkName: "parts-page" */ "../pages/parts/Parts")
);

const ProjectsComponent = React.lazy(
  () =>
    import(/* webpackChunkName: "projects-page" */ "../pages/projects/Projects")
);

const ProjectCreateComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "project-create-page" */ "../pages/project-create/ProjectCreate"
    )
);

const SpecificationsComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "specifications-page" */ "../pages/specifications/Specifications"
    )
);

const SpecificationComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "specification-page" */ "../pages/specification/Specification"
    )
);

const SpecificationCreateComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "specification-create-page" */ "../pages/specification-create/SpecificationCreate"
    )
);

const UsersComponent = React.lazy(
  () => import(/* webpackChunkName: "users-page" */ "../pages/users/Users")
);

const UserCreateComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "user-create-page" */ "../pages/user-create/UserCreate"
    )
);

const LoginLazyComponent = lazyLoad(LoginComponent);
const ForgotPasswordLazyComponent = lazyLoad(ForgotPasswordComponent);
const ResetPasswordLazyComponent = lazyLoad(ResetPasswordComponent);
const DashboardLazyComponent = lazyLoad(DashboardComponent);
const SettingsLazyComponent = lazyLoad(SettingsComponent);
const CodesLazyComponent = lazyLoad(CodesComponent);
const CompanyLazyComponent = lazyLoad(CompanyComponent);
const EngineeringItemsLazyComponent = lazyLoad(EngineeringItemsComponent);
const Error401LazyComponent = lazyLoad(Error401Component);
const Error404LazyComponent = lazyLoad(Error404Component);
const Error500LazyComponent = lazyLoad(Error500Component);
const OperatorsLazyComponent = lazyLoad(OperatorsComponent);
const PartConfigurationsLazyComponent = lazyLoad(PartConfigurationsComponent);
const PartsLazyComponent = lazyLoad(PartsComponent);
const ProjectsLazyComponent = lazyLoad(ProjectsComponent);
const ProjectCreateLazyComponent = lazyLoad(ProjectCreateComponent);
const SpecificationsLazyComponent = lazyLoad(SpecificationsComponent);
const SpecificationLazyComponent = lazyLoad(SpecificationComponent);
const SpecificationCreateLazyComponent = lazyLoad(SpecificationCreateComponent);
const UsersLazyComponent = lazyLoad(UsersComponent);
const UserCreateLazyComponent = lazyLoad(UserCreateComponent);

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/" element={<Navigate to={routeConfig.LOGIN} />} />
          <Route path={routeConfig.LOGIN} element={<LoginLazyComponent />} />
          <Route
            path={routeConfig.FORGOT_PASSWORD}
            element={<ForgotPasswordLazyComponent />}
          />
          <Route
            path={`${routeConfig.RESET_PASSWORD}:uuid`}
            element={<ResetPasswordLazyComponent />}
          />
        </Route>
        <Route element={<DashboardLayout />}>
          <Route
            path={routeConfig.DASHBOARD}
            element={<DashboardLazyComponent />}
          />
          <Route
            path={routeConfig.COMPANY}
            element={<CompanyLazyComponent />}
          />
          <Route
            path={routeConfig.USER_SETTINGS}
            element={<SettingsLazyComponent />}
          />
          <Route path={routeConfig.CODES} element={<CodesLazyComponent />} />
          <Route
            path={`/${routeConfig.CODE}/${routeConfig.ADD}`}
            element={<CodesLazyComponent />}
          />
          <Route
            path={`/${routeConfig.CODE}/:codeId/`}
            element={<CodesLazyComponent />}
          />
          <Route
            path={`/${routeConfig.CODE}/:codeId/${routeConfig.EDIT}`}
            element={<CodesLazyComponent />}
          />
          <Route
            path={routeConfig.ENGINEERINGITEMS}
            element={<EngineeringItemsLazyComponent />}
          />
          <Route
            path={`/${routeConfig.ENGINEERINGITEM}/${routeConfig.ADD}`}
            element={<EngineeringItemsLazyComponent />}
          />
          <Route
            path={`/${routeConfig.ENGINEERINGITEM}/:itemId/`}
            element={<EngineeringItemsLazyComponent />}
          />
          <Route
            path={`/${routeConfig.ENGINEERINGITEM}/:itemId/${routeConfig.EDIT}`}
            element={<EngineeringItemsLazyComponent />}
          />
          <Route
            path={routeConfig.OPERATORS}
            element={<OperatorsLazyComponent />}
          />
          <Route
            path={`/${routeConfig.OPERATOR}/${routeConfig.ADD}`}
            element={<OperatorsLazyComponent />}
          />
          <Route
            path={`/${routeConfig.OPERATOR}/:operatorId/`}
            element={<OperatorsLazyComponent />}
          />
          <Route
            path={`/${routeConfig.OPERATOR}/:operatorId/${routeConfig.EDIT}`}
            element={<OperatorsLazyComponent />}
          />
          <Route
            path={routeConfig.PARTCONFIGURATIONS}
            element={<PartConfigurationsLazyComponent />}
          />
          <Route
            path={`/${routeConfig.PARTCONFIGURATION}/${routeConfig.ADD}`}
            element={<PartConfigurationsLazyComponent />}
          />
          <Route
            path={`/${routeConfig.PARTCONFIGURATION}/:partConfigId/`}
            element={<PartConfigurationsLazyComponent />}
          />
          <Route
            path={`/${routeConfig.PARTCONFIGURATION}/:partConfigId/${routeConfig.EDIT}`}
            element={<PartConfigurationsLazyComponent />}
          />
          <Route path={routeConfig.PARTS} element={<PartsLazyComponent />} />
          <Route
            path={`/${routeConfig.PART}/${routeConfig.ADD}`}
            element={<PartsLazyComponent />}
          />
          <Route
            path={`/${routeConfig.PART}/:partId/`}
            element={<PartsLazyComponent />}
          />
          <Route
            path={`/${routeConfig.PART}/:partId/${routeConfig.EDIT}`}
            element={<PartsLazyComponent />}
          />
          <Route
            path={routeConfig.PROJECTS}
            element={<ProjectsLazyComponent />}
          />
          <Route
            path={`/${routeConfig.PROJECT}/${routeConfig.ADD}`}
            element={<ProjectCreateLazyComponent />}
          />
          <Route
            path={`/${routeConfig.PROJECT}/:projectId/`}
            element={<ProjectCreateLazyComponent />}
          />
          <Route
            path={`/${routeConfig.PROJECT}/:projectId/${routeConfig.EDIT}`}
            element={<ProjectCreateLazyComponent />}
          />
          <Route
            path={routeConfig.SPECIFICATIONS}
            element={<SpecificationsLazyComponent />}
          />
          <Route
            path={`/${routeConfig.SPECIFICATION}/${routeConfig.ADD}`}
            element={<SpecificationCreateLazyComponent />}
          />
          <Route
            path={`/${routeConfig.SPECIFICATION}/:specificationId/`}
            element={<SpecificationLazyComponent />}
          />
          <Route path={routeConfig.USERS} element={<UsersLazyComponent />} />
          <Route
            path={`/${routeConfig.USER}/${routeConfig.ADD}`}
            element={<UserCreateLazyComponent />}
          />
          <Route
            path={`/${routeConfig.USER}/:userId/${routeConfig.EDIT}`}
            element={<UserCreateLazyComponent />}
          />
        </Route>
        <Route element={<ErrorLayout />}>
          <Route path="/errors" />
          <Route
            path={routeConfig.ERROR_401}
            element={<Error401LazyComponent />}
          />
          <Route
            path={routeConfig.ERROR_404}
            element={<Error404LazyComponent />}
          />
          <Route
            path={routeConfig.ERROR_500}
            element={<Error500LazyComponent />}
          />
        </Route>
        <Route
          path="*"
          element={<Navigate to={routeConfig.ERROR_404} replace />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
