import { saveAs } from "file-saver";

/* eslint-disable func-names */
// eslint-disable-next-line no-extend-native
String.prototype.capitalizeFirstLetter = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export const openLinkInNewTab = (url) => {
  window.open(url, "_blank");
};

export const getFullNameInTitleCase = (user) => {
  if (user.first_name && user.last_name) {
    return `${user.first_name.capitalizeFirstLetter()} ${user.last_name.capitalizeFirstLetter()}`;
  }
  if (user.first_name) {
    return `${user.first_name.capitalizeFirstLetter()}`;
  }
  if (user.last_name) {
    return `${user.last_name.capitalizeFirstLetter()}`;
  }
  return "-";
};

export const getDownloadFileName = (type, name, version) => {
  return `${type.capitalizeFirstLetter()}_${name.capitalizeFirstLetter()}_${version.capitalizeFirstLetter()}.xlsx`;
};

export const getInitials = (name = "") => {
  const initials = name
    .replace(/\s+/, " ")
    .split(" ")
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join("");
  return initials;
};

export function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

export const combine = ([head, ...[headTail, ...tailTail]]) => {
  if (!headTail) return head;

  const combined = headTail.reduce((acc, x) => {
    return acc.concat(head.map((h) => `${h}, ${x}`));
  }, []);

  return combine([combined, ...tailTail]);
};

export function getUserType(type) {
  if (type === "material_engineer") {
    return "Material Engineer";
  } else if (type === "design_engineer") {
    return "Design Engineer";
  } else {
    return "Company Admin";
  }
}

export function getETLStatusChipContext(status) {
  if (status === "in_progress") {
    return { color: "info", text: "In Progress" };
  } else if (status === "failed") {
    return { color: "error", text: "Failed" };
  } else if (status === "completed") {
    return { color: "success", text: "Completed" };
  } else {
    return { color: "default", text: "Pending" };
  }
}

export function createCategoryGroupedData(data) {
  // Create a mapping object to hold categories and their content
  const categoryMap = {};

  // Iterate over each item in the JSON data
  data.forEach((item) => {
    const { id, category, content } = item;

    // If the category doesn't exist in the map, initialize it with an empty array
    if (!categoryMap[category]) {
      categoryMap[category] = [];
    }

    // Check if content is not an empty object before adding to content array
    if (
      !(Object.keys(content).length === 0 && content.constructor === Object)
    ) {
      // Add the `id` to each object in the `values` array
      const updatedContent = {
        ...content,
        values: content.values.map((value) => ({
          ...value,
          id, // Include the original item's `id` in each object of `values` array
        })),
      };

      // If content is not empty, add it to the array
      categoryMap[category].push(updatedContent);
    }
  });

  // Convert the map into the desired array format
  return Object.entries(categoryMap).map(([category, content]) => ({
    category,
    content,
  }));
}

export function createTableHeaders(content, hasBranchTable) {
  // Check if content is empty, return an empty array if so
  if (content.length === 0) {
    return [];
  }

  // Check if the first element is an object or a string
  if (typeof content[0] === "object") {
    // Handle array of objects (previous condition)
    const keys = Object.keys(content[0]);
    // Map over the keys to create the headcells structure
    return keys.map((key) => ({
      field: key,
      headerName: key,
      width: 180,
      editable: true,
    }));
  } else if (typeof content[0] === "string") {
    // Handle array of strings (new condition)
    return content.map((key) => ({
      field: key,
      headerName: key,
      width: hasBranchTable ? 60 : content.length === 1 ? 360 : 180,
      editable:
        hasBranchTable && key === "Header Sizes 🡢, Branch Sizes 🡣"
          ? false
          : true,
      sortable: hasBranchTable ? false : true,
    }));
  } else {
    // If neither condition matches, return an empty array
    return [];
  }
}

export function mapHeadersToValues(headers, values) {
  return values.map((row, index) => {
    let obj = { id: index + 1 };
    headers.forEach((header, i) => {
      obj[header] = row[i];
    });
    return obj;
  });
}

export function createTableData(content, hasBranchTable) {
  // Check if content is an array (for first input format) or an object (for second input format)
  const isArrayFormat = Array.isArray(content);
  // If it's array format (first input type)
  if (isArrayFormat) {
    const headers = content[0].headers;
    const values = content.map((item) => {
      return { ...item.values[0] };
    });
    return {
      headers: createTableHeaders(headers, hasBranchTable),
      values: values,
    };
  }
  // If it's the object-based format
  else {
    return {
      headers: createTableHeaders(content.headers, hasBranchTable),
      values: content.values,
    };
  }
}

export function downloadExcelFile(fileName, data) {
  const blob = new Blob([data], {
    type: "application/vnd.ms-excel;charset=utf-8",
  });
  saveAs(blob, fileName);
}

export function downloadXlsxFile(blob, filename = "file.xlsx") {
  // Create a URL from the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a temporary anchor element
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;

  // Append the anchor to the body (required for Firefox)
  document.body.appendChild(a);

  // Trigger the download by simulating a click
  a.click();

  // Clean up: remove the anchor and revoke the object URL
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}
