import React from "react";
import Error500Modal from "./components/Error500Modal";
import AppRoutes from "./routes/AppRoutes";

function App() {
  return (
    <>
      <AppRoutes />
      <Error500Modal />
    </>
  );
}

export default App;
