export const ERROR_401 = "/errors/error-401";
export const ERROR_404 = "/errors/error-404";
export const ERROR_500 = "/errors/error-500";

export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password/";

export const BASE = "/";
export const ADD = "add";
export const EDIT = "edit";
export const DASHBOARD = "dashboard";
export const USER_SETTINGS = "settings";
export const COMPANY = "company";
export const CODE = "code";
export const CODES = "codes";
export const ENGINEERINGITEM = "engineeringitem";
export const ENGINEERINGITEMS = "engineeringitems";
export const OPERATOR = "operator";
export const OPERATORS = "operators";
export const PART = "part";
export const PARTS = "parts";
export const PARTCONFIGURATION = "partconfiguration";
export const PARTCONFIGURATIONS = "partconfigurations";
export const PROJECT = "project";
export const PROJECTS = "projects";
export const SPECIFICATION = "specification";
export const SPECIFICATIONS = "specifications";
export const USER = "user";
export const USERS = "users";
