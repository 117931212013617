import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useOutlet, useLocation } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { styled } from "@mui/system";

import TopBar from "./TopBar";
import NavBar from "./NavBar";

const Root = styled("div")({
  display: "flex",
});

const Main = styled("main")(({ theme }) => ({
  flex: "1 1 auto",
  display: "flex",
  overflow: "hidden",
  padding: theme.spacing(3),
  paddingTop: theme.spacing(16),
}));

const Toolbar = styled("div")(({ theme }) => ({
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}));

const DashboardLayout = ({ path, ...props }) => {
  const outlet = useOutlet();
  const location = useLocation();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <Root>
      <CssBaseline />
      <TopBar
        isMobileNavOpen={isMobileNavOpen}
        setMobileNavOpen={setMobileNavOpen}
        position="fixed"
      />
      <NavBar
        isMobileNavOpen={isMobileNavOpen}
        setMobileNavOpen={setMobileNavOpen}
      />
      <Main>
        <Toolbar />
        {outlet}
      </Main>
    </Root>
  );
};

export default DashboardLayout;
