const API_URLS = {
  LOGIN: "api/login/",
  REFRESH_TOKEN: "api/token/refresh/",
  COMPANY: "api/{companyId}/",
  USERS: "api/{companyId}/users/",
  PROJECTS: "api/{companyId}/projects/",
  PARTS: "api/{companyId}/projects/{projectId}/parts/",
  SPECIFICATIONS: "api/{companyId}/projects/{projectId}/specifications/",
  SPECIFICATION_VERSIONS:
    "api/{companyId}/projects/{projectId}/specifications/{specificationId}/versions/",
  SPECIFICATION_VERSION_IMAGES:
    "api/{companyId}/projects/{projectId}/specifications/{specificationId}/versions/{versionId}/images/",
  SPECIFICATION_VERSION_ETL_PROCESSES:
    "api/{companyId}/projects/{projectId}/specifications/{specificationId}/versions/{versionId}/etl-processes/",
  SPECIFICATION_VERSION_ETL_STEPS:
    "api/{companyId}/projects/{projectId}/specifications/{specificationId}/versions/{versionId}/etl-steps/",
  SPECIFICATION_VERSION_EXTRACTIONS:
    "api/{companyId}/projects/{projectId}/specifications/{specificationId}/versions/{versionId}/extractions/",
  SPECIFICATION_VERSION_BRANCH_TABLE_EXTRACTIONS:
    "api/{companyId}/projects/{projectId}/specifications/{specificationId}/versions/{versionId}/branch-table-extractions/",
  SPECIFICATION_VERSION_TRANFORMATIONS:
    "api/{companyId}/projects/{projectId}/specifications/{specificationId}/versions/{versionId}/transformations/",
  SPECIFICATION_VERSION_CATALOG_TRANFORMATIONS:
    "api/{companyId}/projects/{projectId}/specifications/{specificationId}/versions/{versionId}/catalog-transformations/",
  SPECIFICATION_VERSION_DOWNLOAD_EXCEL:
    "api/{companyId}/projects/{projectId}/specifications/{specificationId}/versions/{versionId}/download-specification-excel/",
  SPECIFICATION_VERSION_DOWNLOAD_CATALOG_EXCEL:
    "api/{companyId}/projects/{projectId}/specifications/{specificationId}/versions/{versionId}/download-catalog-excel/",
};

export default API_URLS;
